import { FC, useEffect, useState } from "react"
import { useHideJdiv } from "@/hooks/hideJdiv"
import { Button } from "@/ui/Button/Button"
import { ButtonGroup } from "@/ui/Button/StyledButton"
import { Popover } from "@/ui/Popover/Popover"
import { Typography } from "@/ui/Typography/Typography"
import { ALLOWED_COOKIE_KEY, ROUTES } from "@/utils/constants"
import { cookies, getExpireOneYear } from "@/utils/common/helpers"
import { Link } from "components/ui/Link"
import {
  StyledAllowCookie,
  StyledAllowCookieWrapper,
} from "./StyledAllowCookie"

export const AllowCookie: FC = () => {
  const [isAllowedCookie, setIsAllowedCookie] = useState<boolean | null>(null)

  useEffect(() => {
    setIsAllowedCookie(!!cookies.get(ALLOWED_COOKIE_KEY))
  }, [])

  useHideJdiv(!isAllowedCookie)

  if (!(isAllowedCookie !== null && !isAllowedCookie)) {
    return null
  }

  return (
    <StyledAllowCookie>
      <StyledAllowCookieWrapper>
        <Popover
          disclosure={<Button />}
          size={"default"}
          isHiddenDisclosure
          hideOnClickOutside={false}
          isShow={true}
        >
          <Typography variant={"p12"}>
            Для улучшения работы сайта и его взаимодействия с пользователями мы
            используем файлы cookie. Продолжая работу с сайтом, вы разрешаете
            использование <Link href={ROUTES.agree}>cookie-файлов</Link>.{" "}
          </Typography>
          <ButtonGroup>
            <Button
              variant={"filled"}
              onClick={() => {
                setIsAllowedCookie(true)
                cookies.set(ALLOWED_COOKIE_KEY, true, {
                  path: "/",
                  expires: getExpireOneYear(),
                })
              }}
            >
              Принять
            </Button>
          </ButtonGroup>
        </Popover>
      </StyledAllowCookieWrapper>
    </StyledAllowCookie>
  )
}
