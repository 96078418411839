import { useEffect } from "react";
import { css } from "@linaria/core";
export const cssHideJdiv = "cssHideJdiv_c1o08imn";
export const useHideJdiv = (enabled = true) => {
  useEffect(() => {
    if (enabled) {
      document.body.classList.add(cssHideJdiv);
    } else {
      document.body.classList.remove(cssHideJdiv);
    }
    return () => {
      document.body.classList.remove(cssHideJdiv);
    };
  }, [enabled]);
};

require("./hideJdiv.linaria.module.css!=!../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./hideJdiv.ts");